const rules = {
    visitor: {
        static: ["posts:list", "home-page:visit"]
    },
    writer: {
        static: [
            "posts:list",
            "posts:create",
            "users:getSelf",
            "home-page:visit",
            "dashboard-page:visit"
        ],
        dynamic: {
            "posts:edit": ({userId, postOwnerId}) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            }
        }
    },
    admin: {
        static: [
            "posts:list",
            "posts:create",
            "posts:edit",
            "posts:delete",
            "users:get",
            "users:getSelf",
            "home-page:visit",
            "Profile:visit",
            "orgprofile:edit",
            "Help:visit",
            "Account:visit",
            "Support Admin:visit",
            "Dashboard:visit",
            "Map:visit",
            "map:list",
            "Create Playlist:visit",
            "Playlists:visit",
            "Screens:visit",
            "camshot:list",
            "dashboardcharts:list",
            "playlists:list",
            "playlists:create",
            "playlists:edit",
            "playlists:delete",
            "playlists:past",
            "playlists:share"
        ]
    },
    adminsaas: {
        static: [
            "posts:list",
            "posts:create",
            "posts:edit",
            "posts:delete",
            "users:get",
            "users:getSelf",
            "home-page:visit",
            "Profile:visit",
            "Dashboard:visit",
            "dashboardcharts:list",
            "Map:visit",
            "map:list",
            "Support Admin:visit",
            "Help:visit",
            "helpvideos:list",
            "helpvideos:create",
            "helpvideos:edit",
            "helpvideos:delete",
            "helpvideos:past",
            "helpvideos:share",
            "Create Playlist:visit",
            "Playlists:visit",
            "Screens:visit", "camshot:list",
            "playlists:list",
            "playlists:create",
            "playlists:edit",
            "playlists:delete",
            "playlists:past",
            "playlists:share"
        ]
    },
    tenantadmin: {
        static: [
            "posts:list",
            "posts:create",
            "posts:edit",
            "posts:delete",
            "users:get",
            "users:getSelf",
            "home-page:visit",
            "Profile:visit",
            "orgprofile:edit",
            "Help:visit",
            "Account:visit",
            "Dashboard:visit",
            "Map:visit",
            "map:list",
            "Create Playlist:visit",
            "Playlists:visit",
            "Screens:visit", "camshot:list",
            "dashboardcharts:list",
            "playlists:list",
            "playlists:create",
            "playlists:edit",
            "playlists:delete",
            "playlists:past",
            "playlists:share"
        ]
    },
    mgtenantadmin: {
        static: [
            "Profile:visit",
            "Map:visit",
            "map:list",
            "Create Playlist:visit",
            "Playlists:visit",
            "Dashboard:visit",
            "Account:visit",
            "Screens:visit", "camshot:list",
            "Account Settings:visit",
            "Help:visit",
            "dashboardcharts:list",
            "camshot:placeholderTBC",
            "screenshot:placeholderTBC",
            "playlists:list",
            "playlists:create",
            "playlists:edit",
            "playlists:delete",
            "playlists:past",
            "playlists:share"
        ]
    },
    publisher: {
        static: [
            "Profile:visit",
            "Create Playlist:visit",
            "Playlists:visit",
            "Dashboard:visit",
            "Screens:visit", "camshot:list",
            "Help:visit",
            "playlists:list",
            "playlists:create",
            "playlists:edit",
            "playlists:delete",
            "playlists:past",
            "playlists:share"
        ]
    },
    ceo: {
        static: [
            "posts:list",
            "posts:create",
            "posts:edit",
            "posts:delete",
            "users:get",
            "users:getSelf",
            "home-page:visit",
            "dashboardcharts:list",
            "Profile:visit",
            "Dashboard:visit",
            "Map:visit",
            "Create Playlist:visit",
            "Playlists:visit",
            "Screens:visit", "camshot:list",
            "CEO:visit"
        ]
    },
    movieadmin: {
        static: [
            "Profile:visit",
            "Dashboard:visit",
            "Create Playlist:visit",
            "Playlists:visit",
            "Movies:visit"
        ]
    }
};

export default rules;
